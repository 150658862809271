import React from 'react'

import ResetPassword from 'components/ResetPassword/ResetPassword'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

export const ResetPasswordPage = () => {
  return (
    <>
      <SiteHeadMetadata title="Reset Password" />
      <ResetPassword />
    </>
  )
}

export default ResetPasswordPage
