import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PaddedFlexCentered from "components/PaddedFlexCentered";

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  isError: boolean;
  resent: boolean;
}
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));
export const ResetPasswordSubmitted = (props: Props) => {
  const classes = useStyles();
  const { onConfirm, isError } = props;
  if (isError) {
    return (
      <>
        <PaddedFlexCentered>
          <Typography component="h1" variant="h5">
            Password Reset Failed
          </Typography>
          <p>
            An error has occurred and your password has not been reset. This
            could be due to the password reset link expiring.
          </p>
          <p>
            If you would like to resend a new password reset request you can
            click the resend button below.
          </p>
          <Button
            className={classes.button}
            disabled={props.resent}
            color="primary"
            fullWidth
            onClick={onConfirm}
            type="button"
            variant="contained"
          >
            {!props.resent ? "Resend" : "Request Re-Sent!"}
          </Button>
          {props.resent && (
            <p>
              A new password reset request link has been sent to your email.
            </p>
          )}
        </PaddedFlexCentered>
      </>
    );
  } else {
    return (
      <>
        <PaddedFlexCentered>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <p>Your password has been reset.</p>
          <p>Your new password may now be used in the AssetLink app!</p>
          <div style={{ display: "flex", gap: "1em", margin: "1em 0" }}>
            <a href="https://play.google.com/store/apps/details?id=com.assetlink_mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                style={{ width: "150px" }}
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>

            <a href="https://apps.apple.com/us/app/assetlink/id1559540557">
              <img
                alt="Download on the Apple Store"
                style={{ width: "150px", padding: "9px" }}
                src="/images/apple_store_badge.webp"
              />
            </a>
          </div>
        </PaddedFlexCentered>
      </>
    );
  }
};

export default ResetPasswordSubmitted;
