import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { PasswordResetDTO, RequestPasswordResetDTO } from "api";
import useApi from "hooks/useApi";

import ResetPasswordSubmitted from "./ResetPasswordSubmitted";

type StyleProps = {
  success: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    autofillHack: {
      "&:-webkit-autofill": {
        transitionDelay: "9999s",
        transitionProperty: "background-color, color",
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: (props: StyleProps) => {
        return props.success ? green[500] : undefined;
      },
      "&:hover": {
        backgroundColor: (props: StyleProps) => {
          return props.success ? green[700] : undefined;
        },
      },
    },
    submitWrapper: {
      position: "relative",
    },
    submitProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const resetPasswordFormSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required()
    .required("Required")
    .min(8, "Your Password Must be at Least 8 Characters"),
  repeatNewPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), "newPassword"], "Passwords Do Not Match"),
});

export interface resetPasswordFormValues {
  newPassword: string;
  repeatNewPassword: string;
  email: string;
  token: string;
}

export const ResetPassword = () => {
  const [passwordResetFormValues, setPasswordResetFormValues] =
    useState<PasswordResetDTO>();
  const [doNavigate, setDoNavigate] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [url, setUrl] = useState<URL | undefined>(undefined);
  const token: string | null | undefined = url && url.searchParams.get("token");
  const emailEncoded: string | null | undefined =
    url && url.searchParams.get("email");
  const email: string | null = emailEncoded ? atob(emailEncoded) : null;

  const passwordResetFetchState = useApi<PasswordResetDTO, never>(
    passwordResetFormValues ? "v1/users/reset-password" : undefined,
    undefined,
    {
      requestOptions: {
        method: "POST",
        body: passwordResetFormValues
          ? JSON.stringify(passwordResetFormValues)
          : undefined,
      },
      dependencies: [passwordResetFormValues],
    }
  );

  const classes = useStyles({
    success: passwordResetFetchState.statusCode === 200,
  });

  const [shouldResend, setShouldResend] = useState<boolean>(false);
  const forgotPasswordFetchState = useApi<never, RequestPasswordResetDTO>(
    shouldResend && email ? "v1/users/request-reset-password" : undefined,
    undefined,
    {
      requestOptions: {
        method: "POST",
        body: email ? JSON.stringify({ email }) : undefined,
      },
      dependencies: [email],
    }
  );

  useEffect(() => {
    setUrl(new URL(window.location.href));
  }, []);

  useEffect(() => {
    if (
      passwordResetFetchState.isLoading === false &&
      passwordResetFetchState.statusCode
    ) {
      setShowInfo(true);
    }
  }, [passwordResetFetchState.isLoading, passwordResetFetchState.statusCode]);

  const handleSubmit = (formVals: resetPasswordFormValues) => {
    if (token && email) {
      const body: PasswordResetDTO = {
        newPassword: formVals.newPassword,
        token: token,
        email: email,
      };
      setPasswordResetFormValues(body);
    }
  };
  if (showInfo) {
    return (
      <ResetPasswordSubmitted
        isError={
          !!(passwordResetFetchState.statusCode === 403) ||
          passwordResetFetchState.isError
        }
        resent={shouldResend}
        onConfirm={() => {
          setDoNavigate(false);
          setShouldResend(true);
        }}
        onCancel={() => {
          setDoNavigate(true);
        }}
      />
    );
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Formik
            initialValues={
              {
                newPassword: "",
                repeatNewPassword: "",
              } as resetPasswordFormValues
            }
            validationSchema={resetPasswordFormSchema}
            onSubmit={handleSubmit}
            render={(formikBag: FormikProps<resetPasswordFormValues>) => {
              const { isSubmitting, isValid, errors, touched } = formikBag;
              return (
                <Form noValidate>
                  <Field
                    name="newPassword"
                    render={({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        autoComplete="new-password"
                        disabled={passwordResetFetchState.isLoading}
                        error={!!(errors.newPassword && touched.newPassword)}
                        fullWidth
                        helperText={touched.newPassword && errors.newPassword}
                        id="newPassword"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ className: classes.autofillHack }}
                        label="New Password"
                        margin="normal"
                        name="newPassword"
                        required
                        type="password"
                        variant="outlined"
                      />
                    )}
                  />
                  <Field
                    name="repeatNewPassword"
                    render={({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        autoComplete="new-password"
                        disabled={passwordResetFetchState.isLoading}
                        error={
                          !!(
                            errors.repeatNewPassword &&
                            touched.repeatNewPassword
                          )
                        }
                        fullWidth
                        helperText={
                          touched.repeatNewPassword && errors.repeatNewPassword
                        }
                        id="repeatNewPassword"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ className: classes.autofillHack }}
                        label="Confirm New Password"
                        margin="normal"
                        name="repeatNewPassword"
                        required
                        type="password"
                        variant="outlined"
                      />
                    )}
                  />
                  <div className={classes.submitWrapper}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.submit}
                      disabled={!isValid || isSubmitting}
                    >
                      Reset Password
                      {passwordResetFetchState.isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.submitProgress}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </Container>
    );
  }
};

export default ResetPassword;
